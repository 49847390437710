import { Col,Image,Typography} from "antd";

import check from '../images/our strenght/check.svg'

const { Title, Paragraph } = Typography;

export const ContainMap = ({proj, json_lang}) => {

    return(
        
        <Col style={proj.style} className="items-center" id="contain-map-cards">
            <div className="items-center text-center">
                <Image src={check} preview={false} id="img-check"></Image>
            </div>
            <div className="text-center mt-6">
                <Title className="text-center text-custom_orange" id="title-contain-cards">{json_lang[proj.title]}</Title>
            </div>
            <div className="items-center text-center mt-7">
                <Paragraph className="text-principal_text_color font-bold text-center md:text-2xl sm:text-xl" id="pr-contain-cards">
                    {json_lang[proj.desc]}
                </Paragraph>
            </div>
        </Col>       
    )
};