const es_json = {
    "Servicios":"Servicios",
    "Portafolio":"Portafolio",
    "Contactanos":"Contactanos",
    "Desarrollo de softwares":"Desarrollo de software",
    "Construimos soluciones de software y aplicaciones con infraestructura escalables":"Construimos soluciones de software  y aplicaciones con infraestructura escalables ",
    "Conocenos":"Conocenos",
    "¿Quiénes somos?":"¿Quiénes somos?",
    "Liricus es un equipo de desarrollo experimentado, de gran antigüedad, sincronizado y de alto rendimiento. Somos expertos en transformar sus necesidades en soluciones.":"Liricus es un equipo de desarrollo experimentado, de gran antigüedad, sincronizado y de alto rendimiento. Somos expertos en transformar sus necesidades en soluciones.",
    "Liricus es un equipo de desarrollo experimentado, de gran antigüedad, sincronizado y de" : "Liricus es un equipo de desarrollo experimentado, de gran antigüedad, sincronizado y de",
    "alto rendimiento": " alto rendimiento. ",
    "Somos expertos en transformar sus necesidades en soluciones.": "Somos expertos en transformar sus necesidades en soluciones.",
    "Nuestro proceso":"Nuestro proceso",
    "El desencadenate":"El desencadenate ",
    "Tienes una necesidad":"Tienes una necesidad",
    "El contacto":"El contacto",
    "¡Dinos qué necesitas!":"¡Dinos qué necesitas!",
    "Evaluación":"Evaluación",
    "Analizaremos tu solicitud.":"Analizaremos tu solicitud.",
    "La respuesta":"La respuesta",
    "Tu solicitud fue aprobada.":"Tu solicitud fue aprobada.",
    "La solución":"La solución",
    "Pongámonos manos a la obra.":"Pongámonos manos a la obra.",
    "¿Qué ofrecemos?":"¿Qué ofrecemos?",
    "Plataforma en la nube AWS y Python":"Plataforma en la nube AWS y Python",
    "Plataformas AWS en framework Python, Django, Flask, etc.":"Plataformas AWS en framework Python, Django, Flask, etc.",
    "Desarrollo de aplicaciones":"Desarrollo de aplicaciones",
    "Objective-C, Swift, Android, Kotlin, React native & flutter":"Objective-C, Swift, Android, Kotlin, React native & flutter",
    "Equipo disponible según necesidad":"Equipo a demanda",
    "Configuración del equipo personalizable, desarrolladores, líderes tecnológicos, administración.":"Configuración del equipo personalizable, desarrolladores, líderes tecnológicos, administración.",
    "Nuestra fuerza":"Nuestra fuerza",
    "Rentabilidad/Rendimiento":"Costo beneficio",
    "Infraestructura flexible on-demand basada en el marco AWS well-architected framework":"Infraestructura flexible on-demand basada en el marco AWS well-architected framework",
    "Las personas en primer lugar":"Las personas en primer lugar",
    "Nuestra cultura de trabajo es nuestro principal recurso, como un equipo boutique que trabaja en estrecha colaboración con nuestros parceros.":"Nuestra cultura de trabajo es nuestro principal recurso, como un equipo boutique que trabaja en estrecha colaboración con nuestros parceros.",
    "Tasa competitiva":"Tasa competitiva",
    "Servicios profesionales altamente calificados con horas/tarifas de economías emergentes.":"Servicios profesionales altamente calificados con horas/tarifas de economías emergentes.",
    "Innovador":"Innovador",
    "Equipo altamente calificado, líderes y arquitectos tecnológicos con más de 10 años de experiencia.":"Equipo altamente calificado, líderes y arquitectos tecnológicos con más de 10 años de experiencia.",
    "Maximiliano Abrutsky":"Maximiliano Abrutsky",
    "Nuestro gerente general tiene más de 15 años de experiencia en el campo, tiene un posgrado en ingeniería de sistemas y tiene experiencia como profesor universitario. Es un emprendedor tecnológico dedicado y uno de los primeros inversionistas en startups de deportes y salud.":"Nuestro gerente general tiene más de 15 años de experiencia en el campo, tiene un posgrado en ingeniería de sistemas y tiene experiencia como profesor universitario. Es un emprendedor tecnológico dedicado y uno de los primeros inversionistas en startups de deportes y salud.",
    "Manuel Luque":"Manuel Luque",
    "Nuestro vicepresidente de ingeniería es el jefe de operaciones de la empresa. Durante los últimos 30 años, ha dirigido equipos de programadores de primer nivel. Tiene experiencia en ingeniería de sistemas y una sólida formación técnica como DBA, arquitecto de la nube de AWS, backend y programador móvil.":"Nuestro vicepresidente de ingeniería es el jefe de operaciones de la empresa. Durante los últimos 30 años, ha dirigido equipos de programadores de primer nivel. Tiene experiencia en ingeniería de sistemas y una sólida formación técnica como DBA, arquitecto de la nube de AWS, backend y programador móvil.",
    "Nuestras tecnologías":"Nuestras tecnologías",
    "Nuestros números":"Nuestros números",
    "10 Tenologías esenciales":"10 Tenologías esenciales",
    "Expertos en los lenguages más utilizados.":"Expertos en los lenguages más utilizados.",
    "+13 Años de experiencia":"+13 Años de experiencia",
    "Equipo multidisciplinario y multicultural.":"Equipo multidisciplinario y multicultural.",
    "3 Industrias principales":"3 Industrias principales",
    "Sportechs, Healthtechs & Insurtechs.":"Sportechs, Healthtechs & Insurtechs.",
    "300 Proyectos":"300 Proyectos",
    "Garantizamos eficiencia y calidad en nuestras entregas.":"Garantizamos eficiencia y calidad en nuestras entregas.",
    "¡Tomemos un café!":"¡Tomemos un café!",
    "No debería tomarnos tanto tiempo programar una reunión de 15 minutos":"No debería tomarnos tanto tiempo programar una reunión de 15 minutos",
    "No debería tomarnos tanto tiempo": "No debería tomarnos tanto tiempo",
    "programar una reunión de 15 minutos": "programar una reunión de 15 minutos",
    "Nombre":"Nombre",
    "Dirección de e-mail":"Dirección de e-mail",
    "Asunto":"Asunto",
    "Mensaje":"Mensaje",
    "Enviar":"Enviar",
    "Tú Nombre": "Tú Nombre",
    "Tú Email": "Tú Email",
    "Interesado en": "Interesado en",
    "Requerido": "Requerido",
    "© Copyright 2022 Servicios tecnológicos - Todos los derechos reservados.":"© Copyright 2022 Servicios tecnológicos - Todos los derechos reservados.",
    "¡Pagina web en construccion!": "¡Pagina web en construcción!",
}   

module.exports = {
 es_json
}