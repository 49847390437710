import { Col,Image,Typography} from "antd";
const { Title, Paragraph } = Typography;


export const TrainProcess = ({proj, isMobile, json_lan}) => {
    
    if (!isMobile) {
        return ( 
            <>
            
                <Col className="">
                    <Image src={proj.img} preview={false} id="cards-p" className=" md:w-32"/>
                    <Title id="title-pr" className="text-center text-custom_orange">{json_lan[proj.title]}</Title>
                    <Paragraph id="paragraph-pr" className="text-principal_text_color font-semibold text-center mt-5 text-2xl">{json_lan[proj.desc]}</Paragraph>
                </Col>
                {proj.vector != null && 
                <Col className="">
                    <Image  src={proj.vector} preview={false} id="vector-p"/>
                </Col>
                }
            </>
            )
    }
    else {
        return ( 
            // <>
            <Col className="">
                <Image src={proj.img} preview={false} id="cards-p" className=" md:w-32"/>
                <Title id="title-pr" className="text-center text-custom_orange">{json_lan[proj.title]}</Title>
                <Paragraph id="paragraph-pr" className="text-principal_text_color font-bold text-center mt-5 text-2xl">{json_lan[proj.desc]}</Paragraph>
            </Col>
            // {proj.vector != null && 
            // <Col className="">
            //     <Image  src={proj.vector} preview={false} id="vector-p"/>
            // </Col>
            // }
        // </>
        )
}
    
    
};