import vector_img_wdwo from '../images/what_do_we_offer_/Vector.png'
import vector_img_wdwo1 from '../images/what_do_we_offer_/Vector (1).png'
import img_mobile from '../images/what_do_we_offer_/App development.png'
import img_trigger from '../images/our process/The _trigger.svg'
import img_contacts from '../images/our process/The contact.svg'
import img_response from '../images/our process/The response.svg'
import img_solution from '../images/our process/The solution.svg'
import img_evaluation from '../images/our process/Evaluation.svg'
import vector_img from '../images/our process/Vector.svg'
import { useState } from 'react'
import { json } from 'react-router-dom'

export const our_numbers_json = [
    {
        title: '10 Tenologías esenciales',
        p:'Expertos en los lenguages más utilizados.'
    },
    {
        title: '+13 Años de experiencia',
        p:'Equipo multidisciplinario y multicultural.'
    },
    {
        title: '3 Industrias principales',
        p:'Sportechs, Healthtechs & Insurtechs.'
    },
    {
        title: '300 Proyectos',
        p:'Garantizamos eficiencia y calidad en nuestras entregas.'
    }
];

export const card_flip = [
    {
        img:vector_img_wdwo1,
        title: 'Plataforma en la nube AWS y Python',
        desc:'Plataformas AWS en framework Python, Django, Flask, etc.'
    },
    {
        
        img:img_mobile,
        title: 'Desarrollo de aplicaciones',
        desc:'Objective-C, Swift, Android, Kotlin, React native & flutter'
    },
    {
        img:vector_img_wdwo,
        title: 'Equipo disponible según necesidad',
        desc:'Configuración del equipo personalizable, desarrolladores, líderes tecnológicos, administración.'
    },
];

export const obj_our_proces= [
    {
        img:img_trigger,
        title: 'El desencadenate',
        desc:'Tienes una necesidad',
        vector: vector_img
    },
    {
        img: img_contacts,
        title: 'El contacto',
        desc:'¡Dinos qué necesitas!',
        vector: vector_img
    },
    {
        img:img_evaluation,
        title: 'Evaluación',
        desc:'Analizaremos tu solicitud.',
        vector: vector_img
    },
    {
        img:img_response,
        title: 'La respuesta',
        desc:'Tu solicitud fue aprobada.',
        vector: vector_img
    },
    {
        img:img_solution,
        title: 'La solución',
        desc:'Pongámonos manos a la obra.',
        vector: null
    },
   
]

export const obj_our_strenght = {
    arr1: [
            {
                title:'Las personas en primer lugar',
                desc:`Nuestra cultura de trabajo es nuestro principal recurso, como un equipo boutique que trabaja en estrecha colaboración con nuestros parceros.`,
                style:{
                    // width:500, 
                    // height:440, 
                    // width:185, 
                    // height:178, 
                    // padding: 40,  
                    borderBottom: '2px solid #E4E4E7',
                }
                    
            },
            {
                title:'Rentabilidad/Rendimiento',
                desc:`Infraestructura flexible on-demand basada en el marco AWS well-architected framework`,
                style:{
                    // width:500, 
                    // height:440, 
                    // width:185, 
                    // height:178, 
                    // padding: 40, 
                    borderBottom: '2px solid #E4E4E7',
                    borderLeft: '2px solid #E4E4E7',
                }
            },
        ],
    arr2:[
            {
                title:'Tasa competitiva',
                desc:'Servicios profesionales altamente calificados con horas/tarifas de economías emergentes.',
                style:{
                    // width:500, 
                    // height:440, 
                    // width:185, 
                    // height:178, 
                    // padding: 40,
                }
            },
            {
                title:'Innovador',
                desc:'Equipo altamente calificado, líderes y arquitectos tecnológicos con más de 10 años de experiencia.',
                style:{
                    // width:500, 
                    // height:440, 
                    // width:185, 
                    // height:178, 
                    // padding: 40, 
                    borderLeft: '2px solid #E4E4E7'
                }
            },

        ]
}

export function useLocalStorage (key, initialValue) {
    const [storedValue, setStoredValue ] = useState(() => {
        try {
            const item = localStorage.getItem(key)
            console.log("item",item)
            return item ? item : initialValue
        }
        catch(e){
            return initialValue
        }
    }); 

    console.log("storedbalues", storedValue)

    const setValue = value => {
        try {
            setStoredValue(value)
            localStorage.setItem(key,value)
        }
        catch(e){
            console.log(e)
        }
    }

    return [storedValue, setValue]
};
