import React from "react";
import { Button, Col, Form, Image, Row, Modal, Typography} from "antd";
import computerimg from '../images/banner/compu.png'
import elipse18 from '../images/who_are_we_/blue_elipse.png'
import elipse19 from '../images/who_are_we_/orange_elipse.png'
import back_snake from '../images/who_are_we_/back_snake.svg'
import elipse20 from '../images/what_do_we_offer_/Ellipse 20.svg'
import elipse21 from '../images/what_do_we_offer_/Ellipse 18 (1).png'
import bg_mapa from '../images/our strenght/Mapita_para_facu.svg'
import lg_python from '../images/our languages/logos_python.png'
import lg_django from '../images/our languages/logos_django-icon.png'
import lg_js from '../images/our languages/logos_javascript (1).png'
import lg_react_nat from '../images/our languages/lg_react_nat.png'
import lg_firebase from '../images/our languages/firebase.png'
import lg_android from '../images/our languages/lg_android.png'
import lg_postgres from '../images/our languages/logos_postgresql.svg'
import vector_lg from  '../images/our languages/Vector.svg'
import eclipse_our_t from  '../images/our languages/eclips_our_t.svg'
import eclipse_our_n from  '../images/our numbers/eclipse_our_n.svg'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import pulse_2 from '../images/our strenght/circle1.svg'
import profile_ceo_pic from '../images/our strenght/maxi_perfil.png'
import manolo_pic from '../images/our strenght/manolo_pic.png'
import {our_numbers_json}  from "../utils/utils";
import { CardFlip } from "../components/CardFlip";
import { TrainProcess } from "../components/TrainProcess";
import { ContainMap } from "../components/ContainMap";
import {card_flip} from "../utils/utils";
import { style_modal, style_modal_responsive } from "../styles/obj_style";
import { obj_our_proces } from "../utils/utils";
import { obj_our_strenght } from "../utils/utils";
import { HashLink } from "react-router-hash-link";
import { useOutletContext } from "react-router-dom";
import useWindowDimensions from "../hooks/useWindowDimensions";



const { Title, Paragraph,} = Typography;
const arr_logos =  [lg_python, lg_django, lg_js, lg_react_nat, lg_android, lg_firebase, lg_postgres];

const renderSlides = () =>
   arr_logos.map(logo => (
      <div>
        <Image src={logo} preview={false}></Image>
      </div>
    ));


const renderSlides2 = () =>
   arr_logos.map(logo => (
      <div>
        <Image src={logo} style={{width:30}} preview={false}></Image>
      </div>
    ));


const renderLogoMob = (arr) =>
  arr.map(logo => (
    <Col>
      <Image src={logo} style={{width:30}} preview={false}></Image>
    </Col>
  ));   


function Arrow(props) {
  let className = props.type === "next" ? "nextArrow" : "prevArrow";
  className += " arrow text-center";
  const char = props.type === "next" ? vector_lg : vector_lg;
  return (
    <span className={className} onClick={props.onClick}>
      <Image src={char} preview={false}></Image>
    </span>
  );
}



export default function Home() {

  const {json_lan, setpPath} = useOutletContext();
  setpPath(window.location.pathname);
  let var_json_lang = json_lan;
  console.log(var_json_lang)
  const [isHover, setIsHover] = React.useState(false);
  const [isHover2, setIsHover2] = React.useState(false);
  const [values, setValues] = React.useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [errorName, setErrorName] = React.useState(false);
  const [errorEmail, setErrorEmail] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(false);
  const [errorSubject, setErrorSubject] = React.useState(false);
  const { height, width } = useWindowDimensions();
 
  const isMobile = () => {
    return width < 992;
  };

  const onFinish = async () => {

    try {
      const data = {
        full_name: values.name,
        email_address: values.email,
        subject: values.subject,
        message: values.message,
        type: "liricus",
      };

      const res = await postData(
        "https://servicedev.liricus.com.ar/send-email-st",
        data
      );
      if (res && res.status === 200) {
        console.log(
          "Thanks for contacting us! We will be in touch with you shortly."
        );
      } else {
        console.log("There was an error trying to send your message.");
      
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  


  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((v) => ({ ...v, [name]: value }));
  };

  const allTrue = (a) => {
    return a == true
  }
  const handleClick = (e) => {
    console.log("handleCLick", values)
    const {name, email, message, subject} = values;
    let are_send_email = []
    if(name.trim().length <= 0){
      console.log("ESVACIO")
      setErrorName(true)
      are_send_email.push(false)
    }
    else{
      setErrorName(false)
      are_send_email.push(true)
    }

    if(/^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(email)){
      setErrorEmail(false)
      are_send_email.push(true)
    }
    else{
      setErrorEmail(true)
      are_send_email.push(false)
    }

    if(message.trim().length <= 0){
      setErrorMessage(true)
      are_send_email.push(false)
    }
    else{
      setErrorMessage(false)
      are_send_email.push(true)
    }
    if(subject.trim().length <= 0){
      setErrorSubject(true)
      are_send_email.push(false)
    }
    else{
      setErrorSubject(false)
      are_send_email.push(true)
    }
    console.log(are_send_email)

    if (are_send_email.every(allTrue)){
       onFinish()
    }
  };

  console.log("ishover", values)


  const postData = async (url, data) => {
    const response = await fetch(url, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return response;
  };

  const renderOurNumbersCol = () =>
  our_numbers_json.map(({title,p}) => (
    <Col className="text-center m-8" id="col-our-number">
      <Title className="text-custom_orange text-center" id="title-our-number" >{var_json_lang[title]}</Title>
      <Paragraph id="pr-our-number" className="text-principal_text_color font-semibold text-center mt-5 text-2xl">{var_json_lang[p]}</Paragraph>           
    </Col>
  ));


  return (
    <div className='bg-contain bg-repeat flex-row w-full' style={{backgroundImage: `url(${back_snake})`}} >
{/* ----------------------------------------------PRIMER SLIDE ------------------------------------------------------*/}
      <div className="">
        <Row justify="justify-evenly">
          <Col className="mt-32 md:w-1/2" id="first-slide">
            
              <Title id="title-first-slide" style className="text-custom_orange mx-auto md:w-10/12 sm:w-1/2 sm:text-sm ">{var_json_lang['Desarrollo de softwares']}</Title>
              <Paragraph className="md:w-4/5 mx-auto text-principal_text_color font-normal leading-10" id="pr-first-slide">{var_json_lang['Construimos soluciones de software y aplicaciones con infraestructura escalables']}</Paragraph>
              <HashLink
                  smooth
                  to={"#contact-us"}
              >
                <Button className="bg-primary w-48 ml-24	h-10 font-bold rounded-3xl mt-16 text-center px-4 py-0 text-white border-0 hover:bg-custom_orange hover:text-white"  id="bt-first-slide">{var_json_lang['Conocenos']}</Button>
              </HashLink>
              
            </Col>
            <Col>
              <Image src={computerimg} className="md:w-9/12" id="img-computer" preview={false} />
            </Col>
          
        </Row>
      </div>
{/*----------------------------------------------------- Who are we?---------------------------------------------------- */}
      {/* <div className="pt-36" class="secsion-separator">
        <img src={elipse18} id="eclipse-w" className="float-right absolute right-0" preview={false} />
        <Title className="text-primary text-center" id="title-first">{var_json_lang['¿Quiénes somos?']}</Title>
        <div className="flex justify-center">
          <Paragraph data-aos="fade-up"  id="who-are-we-pr" className="text-principal_text_color font-semibold text-center mt-5 text-2xl">
            {var_json_lang['Liricus es un equipo de desarrollo experimentado, de gran antigüedad, sincronizado y de']} <span className="text-custom_orange">{var_json_lang['alto rendimiento']}</span>{var_json_lang['Somos expertos en transformar sus necesidades en soluciones.']}
          </Paragraph>
        </div>
        <div className="flex justify-center mt-14">
          <iframe width="1280"height="720" src="https://www.youtube.com/embed/xv4uqKxB_RE" title = "SHIT POST COMPILATION" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <img src={elipse19} id="eclipse-w" preview={false} className='absolute'/>
      </div> */}
{/* -----------------------------------------------------Our process -----------------------------------------------------*/}
      <div className="pt-36" class="secsion-separator">
        <Title className="text-primary text-center pb-4" id="title-first">{var_json_lang['Nuestro proceso']}</Title>
        {!isMobile() && 
        <Row className="flex-1 justify-evenly">
          {obj_our_proces.map((obj)=>(
            <TrainProcess proj={obj} isMobile={isMobile()} json_lan={var_json_lang} />
          ))}
          
        </Row>}

        {isMobile() &&
          <Slider
            className=""
            dots={false}
            slidesToShow={2}
            slidesToScroll={2}
            autoplay={true}
            autoplaySpeed={3000}
            nextArrow={<Arrow type="next" />}
            prevArrow={<Arrow type="prev" />}
          >
            {obj_our_proces.map((obj)=>(
            <TrainProcess proj={obj} isMobile={isMobile()} json_lan={var_json_lang}/>
          ))}
          </Slider>

        }
      </div>
{/* -----------------------------------------------------What do we offer?----------------------------------------------------- */}
      <div className="pt-36" class="secsion-separator" id='what-do-we-offer'>
      <img src={elipse20} id="eclipse-w" preview={false} className="float-right absolute right-0"/>
        <Title className="text-primary text-center" id="title-first">{var_json_lang['¿Qué ofrecemos?']}</Title>
        <img src={elipse21} id="eclipse-w" preview={false} className='absolute'/>
        
        <Row className="justify-evenly items-center">
          {card_flip.map((obj) => (
            <CardFlip proj={obj} json_lang={var_json_lang} isMobile_={isMobile()}/>
          ))}
          
          
        </Row>
      </div>
{/* -----------------------------------------------------Our strength -----------------------------------------------------*/}
      <div className="pt-36" class="secsion-separator">
        <Title className="text-primary text-center " id="title-first">{var_json_lang['Nuestra fuerza']}</Title>
          <div className="w-screen">
            <div id="map-world">
                
            </div>
            <div id='contain-map'>
              <Row>
                {obj_our_strenght.arr1.map((obj) => (
                  <ContainMap proj={obj} json_lang={var_json_lang}/>
                ))}
              </Row>
              <Row>
                {obj_our_strenght.arr2.map((obj) => (
                    <ContainMap proj={obj} json_lang={var_json_lang}/>
                  ))}
              </Row>
            </div>
          </div>
          <Image src={pulse_2} id="core-arg" className="relative"preview={false}
                  onMouseOver={() => setIsHover2(true)} ></Image>
          <Image src={pulse_2} id="core" className="relative"preview={false}
            onMouseOver={() => setIsHover(true)}
          ></Image>
          <Modal id="modal-map"open={isHover} centered  onCancel={() => setIsHover(false)} footer={null}  closable={false}>
            <Row className="items-center" gutter={[8,16]}>
              <Col className="flex">
                <Image className="rounded-full w-40"  id="img-ceo" src={profile_ceo_pic} preview={false}></Image>
              </Col>
              <Col className="ml-7" id="col-ceo">
                <Title className="text-primary font-bold" id="title-ceo">CEO</Title>
                <Paragraph className="text-primary font-bold" id="pr-leader" style={{fontSize:18}}>Founder</Paragraph>
              </Col>
                
            </Row>
                <Title className="text-custom_orange text-center mt-4" id="title-name-ceo">Maximiliano Abrutsky</Title>
                <Paragraph className="text-principal_text_color text-center mt-5 text-xl" id="pr-desc-ceo">
                  {var_json_lang["Nuestro gerente general tiene más de 15 años de experiencia en el campo, tiene un posgrado en ingeniería de sistemas y tiene experiencia como profesor universitario. Es un emprendedor tecnológico dedicado y uno de los primeros inversionistas en startups de deportes y salud."]}
                </Paragraph>
          </Modal>
          <Modal id="modal-map"open={isHover2} centered onCancel={() => setIsHover2(false)} footer={null} bodyStyle={!isMobile() ? style_modal: style_modal_responsive} closable={false}>
            <Row className="items-center" gutter={[8,16]}>
              <Col className="flex">
                <Image className="rounded-full w-40" id="img-ceo" src={manolo_pic} preview={false}></Image>
              </Col>
              <Col className="ml-7" id="col-ceo">
                <Title className="text-primary font-bold" id="title-ceo">CTO</Title>
                <Paragraph className="text-primary font-bold" id="pr-leader" style={{fontSize:18}}>Founder</Paragraph>
              </Col>
                
            </Row>
                <Title className="text-custom_orange text-center mt-4" id="title-name-ceo" >Manuel Luque</Title>
                <Paragraph className="text-principal_text_color text-center mt-5 text-xl" id="pr-desc-ceo">
                  {var_json_lang["Nuestro vicepresidente de ingeniería es el jefe de operaciones de la empresa. Durante los últimos 30 años, ha dirigido equipos de programadores de primer nivel. Tiene experiencia en ingeniería de sistemas y una sólida formación técnica como DBA, arquitecto de la nube de AWS, backend y programador móvil."]}
                </Paragraph>
          </Modal>
        <img src={eclipse_our_t} id="eclipse-w" preview={false} className="float-right absolute right-0"/>
      </div>
{/* ----------------------------------------------------Our technologies ----------------------------------------------------*/}
      <div className="pt-36" class="secsion-separator">
        <Title className="text-primary text-center" id="title-first">{var_json_lang['Nuestras tecnologías']}</Title>
        {!isMobile() && 
          <div className="App">
            <Slider
              className="m-10"
              dots={false}
              slidesToShow={4}
              slidesToScroll={4}
              autoplay={true}
              autoplaySpeed={5000}
              nextArrow={<Arrow type="next" />}
              prevArrow={<Arrow type="prev" />}
            >
              {renderSlides()}
            </Slider>
          </div>
        }
        {
          isMobile() &&
          <div>
            {/* <Row justify="space-evenly">
              {renderLogoMob(arr_logos.splice(0,4))}
            </Row>
            <Row justify="space-evenly">
              {renderLogoMob(arr_logos)}
            </Row> */}
             <Slider
              className="m-10 pl-10"
              dots={false}
              slidesToShow={4}
              slidesToScroll={4}
              autoplay={true}
              autoplaySpeed={5000}
              arrows={false}
            >
              {renderSlides2()}
            </Slider>
          </div>
        }
      </div>
{/*---------------------------------------------------- Our numbers ----------------------------------------------------*/}
      <div className="pt-36" class="secsion-separator">
        <Title className="text-primary text-center " id="title-first">{var_json_lang["Nuestros números"]}</Title>
        <Row className="justify-evenly pt-4">
          {renderOurNumbersCol()}
        </Row>
        <img src={eclipse_our_n} id="eclipse-w" preview={false} className='absolute'/>
      </div>
{/*---------------------------------------------------- EMAIL SENDER ----------------------------------------------------*/}
      <div className="pt-36 " class="secsion-separator" id='contact-us'>
        <Title className="text-primary text-center" id="title-first">{var_json_lang["Contactanos"]}</Title>
        {!isMobile() && 
          <Row className="flex justify-evenly h-full w-full ">
          <Col>
            <Form
                  className="w-full"
                  data-aos="fade-left"
                  data-aos-duration="600"
                  
                >
                  <Row className="space-x-8" style={{minHeight:95}}>
                    <Form.Item>
                      <TextField label={var_json_lang['Tú Nombre'] +'*'}variant="standard" fullWidth 
                        InputProps={{ style: { fontSize: 20} }}
                        InputLabelProps={{ style: { fontSize: 20 } }}
                        name='name'
                        value={values.name || ""}
                        onChange={handleChange}
                        helperText={errorName ? var_json_lang['Requerido'] : ''}
                        error={errorName}
                        />        
                    </Form.Item>
                    <Form.Item>
                      <TextField name='email' label={var_json_lang['Tú Email'] +'*'} variant="standard" fullWidth InputProps={{ style: { fontSize: 20 } }}
                        InputLabelProps={{ style: { fontSize: 20 } }}
                        onChange={handleChange}
                        value={values.email || ""}
                        helperText={errorEmail ? var_json_lang['Requerido'] : ''}
                        error={errorEmail}
                        />     
                    </Form.Item>
                  </Row>
                  <Row className="space-x-8" style={{minHeight:95}}>
                    <Form.Item>
                      <FormControl variant="standard" sx={{ m: 0, minWidth: 120, minHeight:85 }} id='form-mui'>
                         <TextField
                            name="subject"
                            label={var_json_lang['Asunto'] +'*'}
                            fullWidth
                            multiline
                            rows={1}
                            variant="standard"
                            value={values.subject || ""}
                            InputProps={{ style: { fontSize: 20 } }}
                            InputLabelProps={{ style: { fontSize: 20 } }}
                            onChange={handleChange}
                            helperText={errorMessage ? var_json_lang['Requerido']  : ''}
                            error={errorSubject}
                          />
                        
                      </FormControl>
                    </Form.Item>
                  </Row>
                  <Form.Item>
                    <TextField
                      name="message"
                      label={var_json_lang['Mensaje'] +'*'}
                      fullWidth
                      multiline
                      rows={1}
                      variant="standard"
                      value={values.message || ""}
                      InputProps={{ style: { fontSize: 20 } }}
                      InputLabelProps={{ style: { fontSize: 20 } }}
                      onChange={handleChange}
                      helperText={errorMessage ? var_json_lang['Requerido']  : ''}
                      error={errorMessage}
                    />
                  </Form.Item> 
                  <Row className="mt-12" justify="end">
                    <Form.Item className="relative right-0">
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="bg-primary border-0 text-white font-bold px-14 h-18 h-auto text-xl rounded-full inline-flex items-center ml-6 hover:bg-custom_orange"
                          onClick={handleClick}
                          // className="bg-white  border-2 border-amber-500 hover:border-primary/80 text-amber-500 hover:text-primary text-xl px-12 h-12 rounded-full inline-flex items-center ml-6"
                        >
                          {var_json_lang['Enviar']}
                        </Button>
                    </Form.Item>
                  </Row>
                  
              </Form>
            </Col>
            <Col className="text-left" style={{width:700}} >
                <Paragraph className="text-custom_orange text-left font-semibold text-3xl"> {var_json_lang['¡Tomemos un café!'].toUpperCase()} </Paragraph>
                <Paragraph className="text-principal_text_color mt-6 text-3xl text-left " > 
                  {"“"+var_json_lang['No debería tomarnos tanto tiempo programar una reunión de 15 minutos']+"”"}
                </Paragraph>
                {/* <Paragraph className="text-principal_text_color mt-0 text-3xl text-left" > 
                  {var_json_lang['programar una reunión de 15 minutos']+"”"}
                </Paragraph> */}
            </Col>
          </Row>}
          {
            isMobile() && 
           <div style={{ padding :10, paddingRight: 20}}>
           
              <Paragraph className="text-custom_orange text-left font-semibold text-xl" style={{fontSize:14}}> {var_json_lang['¡Tomemos un café!'].toUpperCase()} </Paragraph>
              <Paragraph className="text-principal_text_color mt-6 text-xl text-left "  style={{fontSize:14}}> 
                {"“"+var_json_lang['No debería tomarnos tanto tiempo programar una reunión de 15 minutos']+"”"}
              </Paragraph>
            
            
            <Form
                  className="w-full"
                  data-aos="fade-left"
                  data-aos-duration="600"
                  
                >
                 
                  <Form.Item>
                    <TextField label={var_json_lang['Tú Nombre'] +'*'}variant="standard" fullWidth 
                      InputProps={{ style: { fontSize: 14} }}
                      InputLabelProps={{ style: { fontSize: 14 } }}
                      name='name'
                      value={values.name || ""}
                      onChange={handleChange}
                      helperText={errorName ? var_json_lang['Requerido'] : ''}
                      error={errorName}
                      />        
                  </Form.Item>
                  <Form.Item>
                    <TextField name='email' label={var_json_lang['Tú Email'] +'*'} variant="standard" fullWidth InputProps={{ style: { fontSize: 14 } }}
                      InputLabelProps={{ style: { fontSize: 14 } }}
                      onChange={handleChange}
                      value={values.email || ""}
                      helperText={errorEmail ? var_json_lang['Requerido'] : ''}
                      error={errorEmail}
                      />     
                  </Form.Item>
                
                
                  <Form.Item>  
                    <TextField name='subject' label={var_json_lang['Asunto'] +'*'} variant="standard" fullWidth InputProps={{ style: { fontSize: 14 } }}
                      InputLabelProps={{ style: { fontSize: 14 } }}
                      onChange={handleChange}
                      value={values.subject || ""}
                      helperText={errorSubject ? var_json_lang['Requerido'] : ''}
                      error={errorSubject}
                      /> 
                  </Form.Item>
              
                <Form.Item>
                  <TextField
                    name="message"
                    label={var_json_lang['Mensaje'] +'*'}
                    fullWidth
                    multiline
                    rows={1}
                    variant="standard"
                    value={values.message || ""}
                    InputProps={{ style: { fontSize: 14 } }}
                    InputLabelProps={{ style: { fontSize: 14 } }}
                    onChange={handleChange}
                    helperText={errorMessage ? var_json_lang['Requerido']  : ''}
                    error={errorMessage}
                  />
                </Form.Item> 
                
                  <Form.Item className="">
                    <Row className="mt-8" justify="end">
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="bg-primary border-0 text-white font-bold px-10 h-19 h-auto text-xl rounded-full inline-flex items-center ml-6 hover:bg-custom_orange "
                        onClick={handleClick}
                        style={{fontSize:14}}
                        // className="bg-white  border-2 border-amber-500 hover:border-primary/80 text-amber-500 hover:text-primary text-xl px-12 h-12 rounded-full inline-flex items-center ml-6"
                      >
                        {var_json_lang['Enviar']}
                      </Button>
                    </Row>
                  </Form.Item>      
              </Form>
           </div>

          }

        
      </div>
    </div>
  )
}
