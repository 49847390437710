
import ReactCardFlip from "react-card-flip";
import React from "react";
import { Col,Image,Typography} from "antd";
const { Title } = Typography;

export const CardFlip = ({proj, json_lang,isMobile_}) => {

    const [isFlipped1, setIsFlipped1] = React.useState(false);

    return ( <>
    {!isMobile_ ?
        <Col className="">
            <ReactCardFlip isFlipped={isFlipped1} flipDirection="horizontal" className="bg-contain" style={{height:275,width: 383}} id='card-flip'>
            <div
                
                onMouseEnter={() => setIsFlipped1((prev) => !prev)}
                id='Card-Style'
                className = "CardFront items-center text-center"   
                // style={CardStyle}    
            >
                <Image src={proj.img} preview={false} id="img-card-flip"/>
                <ul>
                    <Title id="title-card-flip" className="text-custom_orange text-sm mt-8" >{json_lang[proj.title]}</Title>
                </ul>
            
            </div>
            <div
                onMouseLeave={() => setIsFlipped1((prev) => !prev)}
                className = "CardBack items-center text-center"   
                id='Card-Style'
                // style={CardStyle} 
            >

                <p className="text-principal_text_color font-semibold text-center mt-10 md:text-2xl " id="card-flip-pr">
                    {json_lang[proj.desc]}
                </p>
            </div>
            </ReactCardFlip>
        </Col>
   :
        <Col className="">
            <ReactCardFlip isFlipped={isFlipped1} flipDirection="horizontal" className="bg-contain" style={{height:275,width: 383}} id='card-flip'>
            <div
                onClick={() => setIsFlipped1((prev) => !prev)}
                id='Card-Style'
                className = "CardFront items-center text-center"   
                // style={CardStyle}    
            >
                <Image src={proj.img} preview={false} id="img-card-flip"/>
                <ul>
                    <Title id="title-card-flip" className="text-custom_orange text-sm mt-8" >{json_lang[proj.title]}</Title>
                </ul>
            
            </div>
            <div
                onClick={() => setIsFlipped1((prev) => !prev)}
                className = "CardBack items-center text-center"   
                id='Card-Style'
                // style={CardStyle} 
            >

                <p className="text-principal_text_color font-semibold text-center mt-10 md:text-2xl " id="card-flip-pr">
                    {json_lang[proj.desc]}
                </p>
            </div>
            </ReactCardFlip>
        </Col>}
    </>
    )
    
};