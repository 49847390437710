import card_background from '../images/what_do_we_offer_/Rectangle_5.png';

export const style_modal = {
    'border-radius': '25px',
    width: '353px'
  };

export const style_modal_responsive = {
  'border-radius': '25px',
    width: '300px',
    height: '378px',
};

export const CardStyle = {
    // border: "0px solid #03506f",
    borderRadius: "30px",
    padding: "40px",
    margin: "20px",
    width: "383px",
    height: "275px",
    // backgroundColor: "#ffffff transparent",
    backgroundImage: `url(${card_background})`,
    backgroundRepeat: 'norepeat',
    backgroundSize:'cover',
    overflow:'hidden'
    
  };

