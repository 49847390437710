import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.less";
import DefaultLayout from "./layouts/DefaultLayout";
import Home from "../src/pages/Home"
import Building from "../src/pages/Building"

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="" element={<DefaultLayout />}>
          <Route path="" element={<Home />} />
          <Route path="portfolio" element={<Building />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
